.filter-row {
  display: grid;
  align-items: center;
  padding: 16px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;

  grid-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @media(min-width: 1280px) {
    display: flex;
    padding: 11px 24px;

    gap: 0;
  }
}

.filter-row__counter {
  display: none;

  @media(min-width: 1280px) {
    display: flex;
    padding-right: 15px;
  }
}
.filter-row__search {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  @media (min-width: 1280px) {
    margin-left: auto;

    // flex-basis: 318px;
    // grid-column: 2 / 3;
    //padding-right: 16px;
  }
}
.selector {
  @media(min-width: 1280px) {
    display: flex;
  }
}
.selector__label {
  display: flex;
  align-items: center;
  margin-top: -3px;
  margin-bottom: 8px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  font-family: var(--ff);
  color: var(--iconColor);
  text-transform: uppercase;
  letter-spacing: 0.005em;

  @media(min-width: 1280px) {
    margin: 0 8px 0 0;
  }
}

.selector__select {
  width: 100%;
  min-width: 193px;
  @media(min-width: 1280px) {
    width: auto;
    max-width: 400px;
  }
}
.counter {
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  font-family: "Avenir Next", sans-serif;
  color: #959595;
  text-transform: uppercase;

  strong {
    font-weight: 500;
    font-family: inherit;
    color: var(--fontColor);
    white-space: nowrap;
  }

  .counter__label {
    display: none;

    font-family: inherit;
    @media(min-width: 1440px) {
      display: flex;

      white-space: nowrap;
    }
  }
}

.filter-row__filter {
  @media (min-width: 1280px) {
    // margin-left: auto;
    padding-left: 16px;
  }
}
.filter-row__mode {
  @media (min-width: 1280px) {
    padding-left: 16px;
  }
}
.filter-row__action {
  grid-column: 1 / -1;
  @media (min-width: 1280px) {
    //grid-column: -1 / -2;
  }
  .button {
    width: 100%;
    @media(min-width: 1280px) {
      width: 125px;
      margin-left: 16px;
    }
  }
}

.filter-dropdown {
  display: block;
  min-width: 182px;
}

