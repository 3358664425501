// prime ng scrollpanel
.p-scrollpanel-content {
  width: calc(100% + 35px) !important;
}
.sidebar__body {
  .p-scrollpanel-content {
    width: calc(100% + 18px) !important;
  }
}
// .chat {
//   .p-scrollpanel-content {
//     width: calc(100% + 18px) !important;
//     height: 100%;
//     padding-bottom: 0;
//   }
// }
.p-scrollpanel-wrapper {
  position: relative;
}
.p-scrollpanel .p-scrollpanel-bar {
  background: #b4b4b4;
}
.p-scrollpanel-bar-y {
  position: absolute !important;
  right: 5px !important;

  width: 2px !important;
}

.p-scrollpanel-hidden {
  display: none !important;
}
// Horizontal
.p-scrollpanel-bar-x {
  position: absolute !important;
  bottom: 5px !important;

  height: 2px !important;
}

.layout--notifications {
  .ng-scroll-viewport {
    overflow: visible !important;

    contain: none !important;
  }
  .ng-scroll-layer,
  .ng-scroll-viewport-wrapper,
  .ng-scrollbar-wrapper,
  .ng-scroll-viewport {
    position: relative !important;
  }
}

// ng-scroll
ng-scrollbar.scroll-event {
  height: 100%;

  border: 0;
  border-radius: 0;

  --scrollbar-border-radius: 10px;

// --scrollbar-hover-size: 2px;
  // --scrollbar-size: 2px;
  --scrollbar-thumb-color: #b4b4b4;
  --scrollbar-track-color: transparent;

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */

    background: transparent; /* Optional: just make scrollbar invisible */
  }
}
.sidebar__body {
  .ng-scroll-content {
    //height: 100%;
    min-height: 100%;
  }
}
