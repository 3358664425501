app-avatar-uploader {
  .uploader {
    display: block;
    width: 100%;
    height: 88px;
    margin-bottom: 0;

    @media(min-width: 1280px) {
      width: 200px;
      height: 100px;
    }
    &.is-rounded {
      width: 100px;
      height: 100px;
      overflow: hidden;

      border-radius: 50%;
      .ngx-file-drop__drop-zone {
        border-radius: 50%;
      }
      .uploaded-file img {
        border-radius: 50%;

        object-fit: cover;
      }
    }
    .drag-input {
      width: 100%;
      height: 100%;
    }
    .ngx-file-drop__drop-zone,
    .uploaded-file {
      width: 100%;
      height: 100%;
    }

    .uploader-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0;

      .uploader-box__label {
        margin-bottom: 0;
        padding-top: 0;

        color: var(--fontColor);
        strong {
          color: #2f80ed;
        }
      }
    }

    .uploaded-file {
      position: absolute;
      top: 0;
      left: 0;

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center;
      }
    }

    .uploaded-file__change {
      position: absolute;
      top: -1px;
      left: -1px;
      z-index: 1;

      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% + 2px);
      height: calc(100% + 2px);

      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      letter-spacing: 0.005em;
      text-shadow: none;

      background: rgba(5, 5, 5, 0.54);
      border-radius: 6px;
      opacity: 0;

      transition: all 0.35s ease;
    }
  }

  &:hover .uploaded-file__change {
    opacity: 1;
  }
}
