// Approve control: thread list, tools chats, tools sidebar
.approve-control {
  display: flex;
  align-items: center;
  padding: 0;

  font-size: 16px;
  line-height: 1;
  color: var(--baseColor);
  letter-spacing: 0.005em;
  white-space: nowrap;

  background-color: transparent;
  border: none;
  cursor: pointer;

  --baseColor: #364652;

  @media(min-width: 1280px) {
    font-size: 14px;
  }

  .circle {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-right: 12px;

    @media(min-width: 1280px) {
      margin-right: 6px;
    }

    #circle {
      display: block;
      width: 100%;
      height: 100%;

      fill: currentColor;
    }

    #check {
      display: none;

      fill: currentColor;
    }
  }

  &:focus {
    color: #6f7a82;
  }

  &:active {
    color: var(--baseColor);
  }


  &:hover {
    color: #6f7a82;

    #check {
      display: block;

      fill: var(--accentPrimary);
    }
  }

  &.approved {
    color: var(--accentPrimary);

    .circle {
      #check {
        display: block;
      }
    }

    &:hover {
      .circle #check {
        display: none;
      }
    }
  }

  &[disabled] {
    border: 1px solid #e0e0e0;
    opacity: 1;

    pointer-events: none;

    .approved-persone {
      pointer-events: all;
    }
  }

  &.approved[disabled]:hover {
    #check {
      display: block;

      fill: currentColor;
    }
  }
}

.approve-control--button {
  min-width: 114px;
  padding: 11px 11px 11px 14px;

  font-size: 14px;
  line-height: 14px;
  color: #ffffff;

  background: #52b683;
  background-color: var(--accentPrimary);
  border: 1px solid #52b683;
  border-radius: 4px;

  --baseColor: #ffffff;

  &:hover,
  &:active {
    color: var(--baseColor);

    background: #74c49b;

    .circle {
      #check {
        display: block;

        fill: #ffffff;
      }
    }
  }

  &:active {
    background: #429f71;
  }

  &.approved {
    background-color: #ffffff;

    --baseColor: var(--accentPrimary);
  }
}

.approved-persone {
  position: relative;

  .icon {
    width: 16px;
    height: 16px;
    margin-top: 0;
    margin-left: 1px;

    color: var(--iconColor);

    cursor: pointer;
  }

  .approved-persone__hint {
    display: none;
  }

  &:hover {
    .approved-persone__hint {
      position: absolute;
      top: -5px;
      left: 24px;
      z-index: 10;

      display: block;
      width: auto;
      max-width: 180px;
      padding: 5px 8px 4px;

      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: var(--fontColor);
      letter-spacing: 0.005em;
      white-space: normal;
      word-wrap: break-word;

      background-color: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 2px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}

.message-footer__content-element .approve-control[disabled] {
  border: none;
  opacity: 0.7;
}
