.layout__title {
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 8px;
  padding-left: 0;

  @media(min-width: 1280px) {
    padding-top: 33px;
  }

  h2 {
    display: flex;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;

    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    font-family: "Avenir Next", sans-serif;
    color: var(--fontColor);
    text-transform: uppercase;
    text-decoration: none;

    -webkit-line-clamp: 2;
            line-clamp: 2;

    span {
      font-family: inherit;
    }

    @media(min-width: 1280px) {
      flex-wrap: nowrap;
    }
  }

  .dot-divider {
    display: inline-flex;
    margin: 0 8px;
  }
}

.back-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 24px;
  padding: 0;

  color: #818393;

  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: #abadba;
  }

  &:focus {
    color: #abadba;
  }

  &:active {
    color: #5d5f6f;
  }
}
