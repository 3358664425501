.droplist {
  position: absolute;

  min-width: 209px;
  padding: 0 24px;

  background-color: #ffffff;
  box-shadow: 0 1px 8px rgba(143, 143, 143, 0.32);

  --droplistHead: 71px;
  --droplistSearch: 56px;

  @media(min-width: 1024px) {
    padding: 0;

    --droplistHead: 0;
    --droplistSearch: 40px;
  }
}

.droplist__mobile-header,
.droplist__header {
  display: flex;
  // justify-content: space-between;
  align-content: center;
  align-items: center;
  height: var(--droplistHead);
  // margin-bottom: 28px;

  font-weight: 600;
  font-size: 18px;
  line-height: 136%;
  font-family: "Avenir Next", sans-serif;
  color: var(--fontColor);
  text-transform: uppercase;

  @media(min-width: 1024px) {
    display: none;
  }

  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    padding: 0;

    color: #818393;

    background-color: transparent;
    border: none;
  }
}

.droplist__header {
  @media(min-width: 1024px) {
    display: flex;
    height: 34px;
    margin-bottom: 8px;
    padding-top: 12px;
    padding-left: 16px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-transform: none;

    .close {
      display: none;
    }
  }
}

.droplist__search {
  position: relative;

  height: var(--droplistSearch);
  margin-top: 12px;

  background: #ffffff;

  @media(min-width: 1024px) {
    height: calc(var(--droplistSearch) + 2px);
    margin-top: 0;
    padding-top: 2px;
  }

  input {
    display: flex;
    align-items: center;
    min-width: 100%;
    height: 40px;
    margin-right: 0;
    margin-bottom: 16px;
    margin-left: 0;
    padding-left: 39px;

    font-size: 16px;
    line-height: 19px;
    color: var(--fontColor);
    letter-spacing: 0.005em;

    background: #f6f6f7;
    border: none;
    border-radius: 4px;

    @media(min-width: 1024px) {
      padding-left: 40px;

      font-weight: normal;
      font-size: 14px;
      line-height: 17px;

      background: #ffffff;
      border-bottom: 1px solid #f6f6f7;
      border-radius: 0;
    }

    &:hover {
      border-color: #bdbdbd;
    }

    &:focus,
    &:active {
      border-color: var(--accentSecondary);
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 13px;
    left: 15px;

    width: 16px;
    height: 16px;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M14.5 13.5L12 11.1C13 9.9 13.4 8.4 13.3 6.8 13.2 5.3 12.5 3.9 11.3 2.9 10.2 1.8 8.7 1.3 7.2 1.3 5.6 1.4 4.2 2 3.1 3.1 2 4.2 1.4 5.6 1.3 7.2 1.3 8.7 1.8 10.2 2.9 11.3 3.9 12.5 5.3 13.2 6.8 13.3 8.4 13.4 9.9 13 11.1 12L13.5 14.5C13.6 14.5 13.7 14.6 13.7 14.6 13.8 14.6 13.9 14.7 14 14.7 14.1 14.7 14.2 14.6 14.3 14.6 14.3 14.6 14.4 14.5 14.5 14.5 14.6 14.3 14.7 14.2 14.7 14 14.7 13.8 14.6 13.7 14.5 13.5ZM7.3 12C6.4 12 5.5 11.7 4.7 11.2 4 10.7 3.4 10 3 9.1 2.7 8.3 2.6 7.3 2.8 6.4 2.9 5.5 3.4 4.7 4 4 4.7 3.4 5.5 2.9 6.4 2.8 7.3 2.6 8.3 2.7 9.1 3 10 3.4 10.7 4 11.2 4.7 11.7 5.5 12 6.4 12 7.3 12 8.6 11.5 9.8 10.6 10.6 9.8 11.5 8.6 12 7.3 12Z' fill='%23818393'/%3E%3C/svg%3E%0A");

    @media(min-width: 1024px) {
      left: 16px;
    }
  }
}

.droplist__title {
  display: none;

  @media(min-width: 1024px) {
    display: flex;
    align-items: center;
    height: 32px;
    margin-top: 12px;
    padding: 4px 16px;

    color: var(--fontColor);

    cursor: default;
  }
}

.droplist__content {
  max-height: calc(100% - var(--droplistHead) - var(--droplistSearch));
  overflow: auto;

  @media(max-width: 1023px) {
    .p-scrollpanel {
      height: inherit !important;
      max-height: inherit !important;
    }
  }

  @media(min-width: 1024px) {
    position: relative;

    display: block;
  }
}

// Droplist on mobile - position flex
@media(max-width: 1023px) {
  .droplist {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    width: 100%;
    height: 100%;
  }
}
