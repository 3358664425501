.p-tabview {
  height: inherit;

  .p-tabview-panels {
    height: calc(100% - 51px);
    padding: 0;
  }
  .p-tabpanel {
    height: 100%;
  }
  .p-tabview-panel {
    height: 100%;
  }
  .p-tabview-nav {
    display: flex;
    align-items: flex-end;
    height: 52px;
    padding-right: 16px;
    padding-left: 16px;

    border-bottom: 1px solid #e0e0e0;
    li {
      height: 30px;
      margin-bottom: -2px;
      margin-left: 40px;
      &:first-child {
        margin-left: 0;
      }
      &:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        background: transparent;
      }
      .p-tabview-nav-link {
        height: 100%;
        margin: 0;
        padding: 0 0 8px;

        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        font-family: "Avenir Next", sans-serif;
        text-align: center;
        color: #929292;

        background: transparent;
        border: none;

        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }
      }
      .p-tabview-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        font-family: "Avenir Next", sans-serif;
      }
    }
  }
}

.p-tabview {
  .p-highlight {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;

      width: 100%;
      height: 3px;

      background: var(--accentPrimary);
      border-radius: 2px;
    }
  }
  .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: var(--fontColor);

    background-color: transparent;
    border: none;
  }
}
