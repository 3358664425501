.p-checkbox {
  display: inline-flex;
  width: 13px;
  height: 13px;
  vertical-align: bottom;
  cursor: pointer;
  user-select: none;

  .p-checkbox-box {
    width: 13px;
    height: 13px;
    background-color: #ffffff;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 11px !important;
    border: 2px solid #818393;
    border-radius: 2px;

    checkicon {
      display: none;
    }

    &:hover {
      background-color: #ffffff;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='12' viewBox='0 0 15 12' fill='none'%3E%3Cpath d='M13.9 1.1C13.8 1 13.7 0.9 13.6 0.9 13.5 0.8 13.4 0.8 13.2 0.8 13.1 0.8 13 0.8 12.8 0.9 12.7 0.9 12.6 1 12.5 1.1L5.1 8.6 1.9 5.4C1.8 5.3 1.7 5.3 1.6 5.2 1.5 5.2 1.3 5.1 1.2 5.1 1.1 5.1 0.9 5.2 0.8 5.2 0.7 5.3 0.6 5.3 0.5 5.4 0.4 5.5 0.3 5.7 0.3 5.8 0.2 5.9 0.2 6 0.2 6.2 0.2 6.3 0.2 6.4 0.3 6.6 0.3 6.7 0.4 6.8 0.5 6.9L4.4 10.7C4.4 10.8 4.6 10.9 4.7 10.9 4.8 11 4.9 11 5.1 11 5.2 11 5.3 11 5.4 10.9 5.6 10.9 5.7 10.8 5.8 10.7L13.9 2.6C14 2.5 14.1 2.4 14.2 2.2 14.2 2.1 14.3 2 14.3 1.8 14.3 1.7 14.2 1.6 14.2 1.4 14.1 1.3 14 1.2 13.9 1.1Z' fill='%2352B683'/%3E%3C/svg%3E%0A");
      border-color: #818393 !important;
    }

    &.p-highlight {
      background-color: var(--accentPrimary);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='9' viewBox='0 0 11 9' fill='none'%3E%3Cpath d='M1.9 4H1.9C2 4 2.1 4.1 2.1 4.1 2.2 4.1 2.3 4.2 2.3 4.2L1.9 4ZM1.9 4H1.1C0.9 4 0.8 4.3 0.9 4.4L0.9 4.4 4.1 8.5 4.1 8.5C4.3 8.8 4.7 8.8 4.9 8.5L4.9 8.5 10.7 1.2C10.7 1.2 10.7 1.2 10.7 1.2 10.8 1.1 10.7 0.9 10.5 0.9H9.6C9.5 0.9 9.3 0.9 9.2 1.1 9.2 1.1 9.2 1.1 9.2 1.1L4.5 7M1.9 4L4.5 7M4.5 7L2.3 4.2 4.5 7Z' style='fill:white;stroke-width:0.3;stroke:white'/%3E%3C/svg%3E%0A");
      border-color: var(--accentPrimary) !important;
    }
  }

  &:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background-color: var(--accentPrimary);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='9' viewBox='0 0 11 9' fill='none'%3E%3Cpath d='M1.9 4H1.9C2 4 2.1 4.1 2.1 4.1 2.2 4.1 2.3 4.2 2.3 4.2L1.9 4ZM1.9 4H1.1C0.9 4 0.8 4.3 0.9 4.4L0.9 4.4 4.1 8.5 4.1 8.5C4.3 8.8 4.7 8.8 4.9 8.5L4.9 8.5 10.7 1.2C10.7 1.2 10.7 1.2 10.7 1.2 10.8 1.1 10.7 0.9 10.5 0.9H9.6C9.5 0.9 9.3 0.9 9.2 1.1 9.2 1.1 9.2 1.1 9.2 1.1L4.5 7M1.9 4L4.5 7M4.5 7L2.3 4.2 4.5 7Z' style='fill:white;stroke-width:0.3;stroke:white'/%3E%3C/svg%3E%0A");
    border-color: var(--accentPrimary);
  }
}