.status-box {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;

  width: 80%;
  max-width: 378px;
  padding: 12px 16px;

  color: #ffffff;

  background: rgba(30, 30, 31, 0.76);
  border-radius: 2px;
  transform: translate(-50%, -50%);

  @media(min-width: 1280px) {
    width: auto;
  }
}

.status {
  padding: 8px 12px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 136%;
  font-family: "Avenir Next";
  text-align: center;
  color: #ffffff;
}

.status-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background: rgba(255, 255, 255, 0.87);

  backdrop-filter: blur(5px);
}

.status-overlay--mobile-warning {
  z-index: 30;

  display: block;

  @media(min-width: 1280px) {
    display: none;
  }
}

