
.empty-sidebar-message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-family: "Avenir Next", sans-serif;
  color: #929292;
}

.empty-sidebar-message__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  padding-top: 66px;
  img {
    display: block;
    width: 60px;
    margin-bottom: 19px;
  }

  h3 {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    font-family: "Gibson", sans-serif;
    color: #505050;
    text-transform: uppercase;
    letter-spacing: 0.005em;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #929292;
    letter-spacing: 0.005em;
    svg {
      display: inline;

      vertical-align: bottom;
    }
  }
}
.empty-icon {
  display: inline-block;

  white-space: nowrap;

  svg {
    width: 20px;
    height: 20px;
  }
}
