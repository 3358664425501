.subscribers {
  position: relative;

  display: inline-flex;
  align-items: center;
  overflow: hidden;

  --circleSize: 58px;

  @media(min-width: 1024px) {
    overflow: visible;

    --circleSize: 32px;
  }
}

.subscribers__button {
  position: relative;

  flex-shrink: 0;
  width: var(--circleSize);
  height: var(--circleSize);
  margin-left: -12px;
  padding: 15px;

  color: #bdbdbd;

  background: #ffffff;
  border: 1px dashed #bdbdbd;
  border-radius: 50%;
  cursor: pointer;

  @media(min-width: 1024px) {
    padding: 5px;
  }

  .icon {
    width: 100%;
    height: 100%;
  }

  .plus {
    position: absolute;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;

    color: #ffffff;

    background: #bdbdbd;
    border-radius: 50%;

    @media(min-width: 1024px) {
      width: 14px;
      height: 14px;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background: #f9fafc;
    border-color: var(--accentSecondary);

    span {
      background: #bacbf7;
    }
  }
}

.subscribers-list {
  display: flex;
  flex-shrink: 0;
  margin: 0;
  padding: 0 0 0 20px;

  list-style: none;
}

.subscribers-list__item {
  width: var(--circleSize);
  height: var(--circleSize);
  margin-left: -12px;
  overflow: hidden;

  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 50%;

  &--more {
    width: var(--circleSize);
    height: var(--circleSize);
    margin-top: 0;
  }

  img {
    width: 100%;
    height: 100%;
    overflow: hidden;

    border-radius: 50%;

    object-fit: cover;
    object-position: center;
  }
}

// Droplist
.add-subscribers {
  bottom: 46px;
  left: 0;
  z-index: 10;

  @media(max-width: 1199px) {
    z-index: 100;
  }

  @media(min-width: 1024px) {
    width: 234px;
    height: 284px;
    max-height: 284px;
  }

  &--readonly {
    cursor: default;

    .add-subscribers__item {
      color: #1e1e1f;

      cursor: default;

      .image {
        padding: 0;

        border: none;
      }
    }
  }
}

.add-subscribers__list {
  padding-right: 32px;

  @media(min-width: 1024px) {
    max-height: 220px;
    padding-top: 8px;
    padding-right: 0;
  }
}

.add-subscribers__item {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 4px 2px;

  text-align: left;
  color: #929292;

  cursor: pointer;

  @media(min-width: 1024px) {
    padding: 4px 16px;
  }

  &:hover {
    background: #f9fafc;
  }

  &:focus,
  &:active {
    background: transparent;
  }

  &--all {
    .image {
      padding: 4px;
    }
  }

  &.active {
    color: var(--fontColor);


    @media(max-width: 1023px) {
      position: relative;

      padding-right: 60px;
    }

    .image {
      border-color: #52b683;
      box-shadow: 0 0 0 1px #52b683;
    }
  }

  .image {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    padding: 1px;
    overflow: hidden;

    border: 1px solid #c5c5c5;
    border-radius: 50%;

    @media(min-width: 1024px) {
      margin-right: 8px;
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
    }
  }

  .name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

    font-size: 16px;
    line-height: 22px;
    color: inherit;
    letter-spacing: 0.005em;
    word-wrap: break-word;

    -webkit-line-clamp: 3;

    @media(min-width: 1024px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}