.calendar-box {
  max-width: 293px;

  --fz: 10px;

  @media(max-width: 1279px) {
    position: relative;
    z-index: 100;
  }
}
.date-selector .p-inputwrapper-filled .p-inputtext {
  background: #f6f6f7;
}
.date-selector .p-inputwrapper-focus .p-inputtext {
  border-color: var(--accentPrimary);
}
.p-calendar .p-inputtext {
  height: 40px;
  padding: 12px 11px;
}
.calendar-box {
  &.p-datepicker:not(.p-datepicker-inline) {
    box-shadow: none;
  }
  .p-link {
    font-size: var(--fz);
  }
  &.p-datepicker table {
    margin: 3px 0;

    font-size: var(--fz);

    th {
      padding: 8px;
    }
    td {
      padding: 5px 8px 9px;
    }
  }

  &.p-datepicker .p-datepicker-header {
    padding: 0 0 3px;
    .p-datepicker-title {
      display: flex;

      line-height: 1;

      gap: 0 10px;
      .p-datepicker-year,
      .p-datepicker-month {
        margin-right: 0;
        padding: 0;
      }
    }

    .p-datepicker-prev,
    .p-datepicker-next {
      width: 24px;
      height: 24px;
    }
  }

  &.p-datepicker table td > span {
    width: calc(var(--fz)*2);
    height: calc(var(--fz)*2);
  }

  .p-datepicker-prev-icon {
    width: 16px;
    height: 16px;

    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8.5 12.8 5.7 5.6c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-4.9-5 4.9-5c.4-.4.4-1 0-1.4-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3l-5.7 5.6c-.4.5-.4 1.1 0 1.6 0-.1 0-.1 0 0Z' fill='%23000'/%3E%3C/svg%3E");
    background-size: contain;
  }
  .p-datepicker-next-icon {
    width: 16px;
    height: 16px;

    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.54 11.29 9.88 5.64a1 1 0 1 0-1.42 1.41l4.95 5L8.46 17a1 1 0 0 0 0 1.41 1 1 0 0 0 .71.3.999.999 0 0 0 .71-.3l5.66-5.65a1 1 0 0 0 0-1.47Z' fill='%23000'/%3E%3C/svg%3E");
    background-size: contain;
  }
}

// mobile behaviour
@media(max-width: 1279px) {
  .calendar-holder {
    position: relative;
  }

  .calendar-box.p-datepicker {
    position: relative;
    top: 0 !important;
    left: 0 !important;

    box-shadow: none;
  }
}
@media(min-width: 1280px) {
  .calendar-holder {
    position: absolute;
    top: 0;
    left: calc(100% + 2px);

    width: 293px;
    min-height: 250px;
  }
  .calendar-box.p-datepicker {
    top: 0 !important;
    left: 0 !important;

    border: 1px solid #e9e9e9;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(185, 185, 185, 0.1);
  }
}


