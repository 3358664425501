.button-group {
  display: flex;
  padding-left: 1px;
}

.button-group__item {
  z-index: 1;

  width: 40px;
  height: 40px;
  margin-left: -1px;
  padding: 7px;

  color: #818393;

  background: #ffffff;
  border: 1px solid #bdbdbd;
  cursor: pointer;
  &:focus {
    z-index: 2;

    color: #818393;

    border-color: #818393;
  }
  &.active,
  &:active,
  &:hover {
    z-index: 2;

    color: var(--accentPrimary);

    background-color: #f4faf7;
    border-color: var(--accentPrimary);
  }
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
}

