// Uploader
.uploader {
  margin-top: 4px;
  margin-bottom: 16px;

  // Global Uploader box override
  ngx-file-drop .ngx-file-drop__drop-zone {
    position: relative;

    display: flex;
    width: 100%;
    height: 100%;
    min-height: 91px;

    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: var(--fontColor);
    white-space: nowrap;
    text-shadow: none;

    background: #f6f6f7;
    border: 1px dashed #bdbdbd;
    border-radius: 6px;
    outline: none;
    cursor: pointer;

    &:hover {
      background: #f9fafc;
      border-color: var(--accentSecondary);
    }

    &:active {
      background: #eff3fc;
      border-color: #3c5bac;
    }

    &--over {
      background: #f9fafc;
      border: 1px dashed var(--accentSecondary);
    }
  }

  .drag-input {
    width: 100%;
  }

  // Uploader box
  .uploader-box {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    padding-right: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
  }

  .uploader-box__label {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    padding-top: 13px;

    font-size: 14px;
    line-height: 17px;
    color: #929292;

    strong {
      font-weight: normal;
      color: #2f80ed;
    }

    .mobile-message {
      display: block;
      @media(min-width: 1280px) {
        display: none;
      }
    }

    .desktop-message {
      display: none;
      @media(min-width: 1280px) {
        display: inline-flex;
      }
    }

    span {
      display: none;

      @media(min-width: 1280px) {
        display: initial;
      }
    }

    .icon {
      margin-right: 8px;
    }
  }

  // Uploaded files
  .uploaded-grid {
    display: grid;

    gap: 16px;
    grid-template-columns: repeat(auto-fill, 136px);
  }
  .uploaded-file {
    position: relative;

    display: flex;
    flex-direction: column;
  }

  .uploaded-file__image {
    position: relative;

    flex-shrink: 1;
    flex-basis: 136px;
    width: 136px;
    height: 136px;
    margin-bottom: 5px;

    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 2px;

    @media(min-width: 1280px) {
      flex-shrink: 0;
      width: 136px;
      height: 136px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;
    }
  }
  .uploaded-file__class-name {
    font-size: 14px;
    line-height: 17px;
    color: #1e1e1f;
    letter-spacing: 0.005em;
  }
  .uploaded-file__info {
    flex-shrink: 0;
    flex-basis: 157px;
    padding-left: 16px;

    @media(min-width: 1280px) {
      flex-basis: 240px;
      padding-left: 23px;
    }
  }

  .uploaded-file__delete {
    position: absolute;
    top: -5px;
    right: -5px;

    width: 20px;
    height: 20px;
    padding: 1px;

    color: #ffffff;

    background: #828282;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    @media(min-width: 1280px) {
      opacity: 0;
    }

    .icon {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;
    }
  }

  .uploaded-file__image:hover {
    border-color: #537dea;
    .uploaded-file__delete {
      opacity: 1;
    }
  }

  .uploaded-file--other .uploaded-file__image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;

    img {
      height: 80px;
    }

    // .uploaded-file__text {
    //   padding-top: 11px;
    //   overflow: hidden;

    //   font-weight: normal;
    //   font-size: 14px;
    //   line-height: 17px;
    //   text-align: center;
    //   color: #929292;
    //   letter-spacing: 0.005em;
    //   white-space: nowrap;
    // }
  }
  .uploader-error-message {
    margin-top: 4px;

    font-weight: normal;
    font-size: 13px;
    line-height: 100%;
    color: #eb5757;
  }
}
.add-thread__form {
  .ngx-file-drop__drop-zone {
    min-height: 96px;
  }
}

