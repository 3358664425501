.p-skeleton {
  width: inherit !important;
  height: inherit !important;
  min-height: inherit;

  background-color: #f5f5f8;

  &:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0));
  }
}
