// Variables


$ff: "Gibson", sans-serif;
$transition: all 0.45s ease;

:root {
  --transition: #{$transition};

  --accentPrimary: #52b683;
  --accentSecondary: #537dea;
  --ff: #{$ff};
  --fontColor: #1e1e1f;
  --headerH: 62px;
  --iconColor: #818393;

  @media(min-width: 1900px) {
    --headerH: 62px;
  }
}
