*,
*::before,
*::after {
  box-sizing: border-box;

  font-family: $ff;

  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}
html {
  height: 100%;
}
body {
  height: 100%;
  min-height: 100%;
  margin: 0;

  background: #f6f6f7;
}
.visuallyhidden {
  position: absolute !important;

  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden;

  border: 0 !important;

  clip: rect(1px 1px 1px 1px);
}
.disabled:not(.button),
[disabled]:not(.button) {
  cursor: default;
  opacity: 0.7;
  filter: grayscale(1);

  pointer-events: none;
}


.hidden,
[hidden] {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;

  font-family: $ff;
  text-transform: capitalize;
  letter-spacing: 0;
}
h3 {
  font-size: 18px;
  font-family: "Avenir Next", sans-serif;
}
a {
  border-color: transparent;

  &:hover {
    border-color: transparent;
  }
  &::after {
    display: none;
  }
}

a:not(.btn):after {
  display: none;
}

p {
  margin-top: 0;
}
.comment__message {
  p:last-child {
    margin-bottom: 0;
  }
}
.message__body {
  img {
    display: block;
    max-width: 100%;
  }
}
button {
  font-family: $ff;
}
button:focus,
input:focus,
textarea:focus {
  outline: none;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 12px 16px;

  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;

// letter-spacing: 0.01em;
  letter-spacing: 0.02em;

  background: var(--accentPrimary);
  border: 1px solid var(--accentPrimary);
  border-radius: 4px;
  cursor: pointer;

  transition: var(--transition);

  .icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }

  &:hover,
  &:focus {
    background: #74c49b;
  }
  &:active {
    background: #388a60;
  }

  &.disabled,
  &[disabled] {
    color: #828282;

    background-color: #bdbdbd;
    border-color: #bdbdbd;
    cursor: default;
    opacity: 0.24;
  }

  &--clear {
    color: var(--iconColor);

    background-color: #ffffff;
    border: 1px solid var(--iconColor);
    &:hover,
    &:focus {
      color: #818393;

      background: #f7f7f7;
      border-color: #818393;
    }
    &:active {
      background: #ebebeb;
      border-color: #818393;
    }
    &.disabled,
    &[disabled] {
      color: #cccccc;

      background-color: #ffffff;
      border-color: #cccccc;
      opacity: 1;
    }
  }
  &--red {
    color: #ffffff;

    background-color: #eb5757;
    border: 1px solid #eb5757;
    &:hover,
    &:focus {
      color: #ffffff;

      background: #ee7070;
      border-color: #ee7070;
    }
    &:active {
      color: #ffffff;

      background: #e43737;
      border-color: #e43737;
    }
    &.disabled,
    &[disabled] {
      color: #e1e1e1;

      background-color: #efefef;
      border-color: #efefef;
      opacity: 1;
    }
  }
}
.button-icon {
  color: #bdbdbd;

  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #818393;
  }
  &:active {
    color: #5d5f6f;
  }
}
img {
  width: auto;
}

input[type=text],
input[type=submit],
textarea {
  -webkit-appearance: none;
}

.icon {
  display: block;

  fill: currentColor;
}

.message .message-body__content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;

    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #505050;
    letter-spacing: 0.005em;
  }
}
blockquote {
  position: relative;

  margin: 0;
  padding-left: 23px;

  font-style: italic;
  font-weight: 300;
  font-size: inherit;
  line-height: inherit;
  font-family: var(--ff);
  color: inherit;
  letter-spacing: 0.005em;

  border-left: none;

  &::before {
    content: "";
    position: absolute;
    top: 2px;
    left: -1px;

    display: block;
    width: 16px;
    height: 16px;

    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.333 6.667c-.148 0-.29.022-.433.043.046-.155.093-.312.17-.453.075-.206.194-.384.312-.563.099-.194.273-.325.4-.491.135-.162.317-.269.462-.403.142-.14.328-.21.476-.309.155-.088.29-.186.433-.233l.36-.148.316-.131-.324-1.292-.398.096-.459.114c-.18.033-.373.124-.588.208-.212.094-.457.158-.685.31-.23.146-.494.267-.728.462-.226.2-.498.374-.7.63-.22.238-.437.49-.606.774-.195.272-.328.571-.468.866a7.384 7.384 0 0 0-.312.891 8.047 8.047 0 0 0-.256 1.625c-.022.478-.009.876.02 1.164.01.136.028.268.041.36l.017.112.017-.004a3 3 0 1 0 2.933-3.628Zm7.334 0c-.149 0-.292.022-.434.043.046-.155.094-.312.17-.453.076-.206.194-.384.312-.563.1-.194.273-.325.401-.491.134-.162.317-.269.461-.403.142-.14.328-.21.476-.309.155-.088.29-.186.434-.233l.36-.148.315-.131-.323-1.292-.398.096-.46.114c-.18.033-.373.124-.588.208-.21.095-.457.158-.685.311-.23.145-.494.267-.727.461-.226.201-.499.375-.7.63-.22.238-.438.49-.606.774-.196.272-.328.571-.468.866a7.388 7.388 0 0 0-.312.891 8.048 8.048 0 0 0-.256 1.625c-.023.478-.01.876.018 1.164.01.136.03.268.042.36l.017.112.017-.004a3 3 0 1 0 2.934-3.628Z' fill='%23818393'/%3E%3C/svg%3E");
    background-position: center;
    background-size: contain;
  }
}

