@import "./forms.scss";
@import "./dot-menu.scss";
@import "./dropdown.scss";
@import "./droplist.scss";
@import "./modal.scss";
@import "./scroll.scss";
@import "./editor.scss";
@import "./uploader.scss";
@import "./back-link.scss";
@import "./filter-row.scss";
@import "./subscribers.scss";
@import "./button-group.scss";
@import "./avatar.scss";
@import "./avatar-uploader.scss";
@import "./loading.scss";
@import "./approve-button.scss";
@import "./file-info.scss";
@import "./tool-statuses.scss";
@import "_prime-ng-customization.scss";
@import "pinch-zoom.scss";
@import "p-tabs.custom.scss";
@import "p-sidebar.scss";
@import "p-tooltip.scss";
@import "p-checkbox.scss";
@import "p-multiselect.scss";
@import "p-calendar.scss";
@import "p-skeleton.scss";
@import "pdf-viewer.scss";
@import "unreal-player.scss";
@import "video-player.scss";
@import "empty-sidebar.scss";
@import "empty-message.scss";
@import "awesome-checkbox.scss";

.dot-divider {
  display: inline-flex;
  align-self: center;
  width: 4px;
  height: 4px;
  margin: 0 6px;

  vertical-align: middle;

  background: currentColor;
  border-radius: 50%;
}
.notification-item__event {
  strong {
    font-weight: normal;
    color: #1e1e1f;
    letter-spacing: 0.005em;
  }
}

