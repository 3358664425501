
ngx-avatars {
  width: 100%;
  height: 100%;

  font-size: 16px;

  @media(min-width: 1280px) {
    font-size: 12px;
  }
}
.avatar-container {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;

  border-radius: 50%;
  .avatar-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit !important;
    height: inherit !important;

    font-size: inherit !important;
    line-height: 1 !important;
    font-family: "Avenir Next", Helvetica, Arial, sans-serif !important;
    color: #1e1e1f !important;
    letter-spacing: 0.005em;

    object-fit: cover;
    object-position: center;
  }
}

