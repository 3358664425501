/* You can add global styles to this file, and also import other style files */
@import "../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import "./styles/index";
@import "ngx-toastr/toastr";

// app-file-versions-card {
//   display: block;
//   padding-top: 13px;
// }




.pz-zoom-control {
  display: none;
  // TODO refactor this
}


ngx-avatars .avatar-content {
  cursor: inherit;
}
.chat-message-editor {
  p {
    margin-bottom: 0;
  }
}

body.js-burger-menu-open {
  overflow: hidden !important;
  .platform_3dsource_com {
    height: 100% !important;
    overflow: hidden !important;
  }
}
