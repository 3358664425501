ngx-extended-pdf-viewer .zoom {
  height: 100% !important;
}

ngx-extended-pdf-viewer {
  #viewer .page {
    cursor: grab !important;
  }
}

#viewerContainer {
  top: 73px !important;

  cursor: default !important;

  scrollbar-color: rgba(62, 62, 62, 0.87) transparent;
  scrollbar-width: thin;

  @media(min-width: 1280px) {
    top: 0 !important;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px  transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(62, 62, 62, 0.87);
    outline: none;
  }
}
