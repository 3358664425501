.dot-menu {
  position: relative;

  --buttonSize: 18px;
}

.dot-menu__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--buttonSize);
  height: var(--buttonSize);
  padding: 0;

  color: #bdbdbd;

  background-color: transparent;
  border: none;
  cursor: pointer;

  &.active {
    color: var(--accentPrimary);
  }
}

.dot-menu__list {
  position: absolute;
  top: calc(100% + 6px);
  left: -59px;

  min-width: 130px;
  margin: 0;
  padding: 8px 0;

  background: #f5f5f6;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(185, 185, 185, 0.1);
}

.dot-menu__list::before {
  content: "";
  position: absolute;
  top: -10px;
  left: calc(50% - 2px);

  width: 0;
  height: 0;

  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent #e9e9e9 transparent;
  //filter: drop-shadow(0px -10px 1px #A3A3A3);
}

.dot-menu__list::after {
  content: "";
  position: absolute;
  top: -8px;
  left: 50%;

  width: 0;
  height: 0;

  border-width: 0 8px 8px 8px;
  border-style: solid;
  border-color: transparent transparent #f5f5f6 transparent;
  //filter: drop-shadow(0px -10px 1px #A3A3A3);
}

.dot-menu__item {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 5px 15px;

  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: var(--fontColor);
  letter-spacing: 0.005em;

  cursor: pointer;

  &:hover {
    color: #ffffff;

    background: var(--accentPrimary);
  }
}

// vertical dot menu - in chat, in project cards, in threads

.comment-menu {
  position: relative;

  width: var(--buttonSize);

  --buttonSize: 32px;
}

.comment-menu__toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--buttonSize);
  height: var(--buttonSize);
  padding: 0;

  color: #bdbdbd;

  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--accentPrimary);
  }

  &.active,
  &:active {
    color: var(--accentPrimary);
  }
}

.comment-menu__list {
  min-width: 130px;
  margin: 0;
  padding: 8px 0;
}

.comment-menu__item {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.comment-menu__button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px 15px;

  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: var(--fontColor);
  letter-spacing: 0.005em;

  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: #ffffff;

    background: var(--accentPrimary);
  }
}

// Overlay menu from dotted button
.comment-menu-overlay {
  z-index: 10;

  width: 132px;
  margin-top: 5px !important;
  margin-left: calc(-1 * (132px / 2) + 16px);

  background: #f5f5f6;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(185, 185, 185, 0.1);

  &--chat {
    z-index: 1010;
  }

  &.p-overlaypanel:before {
    left: 50%;

    margin-left: 0;

    border-bottom-color: #e9e9e9;
    transform: translateX(-50%);
  }

  &.p-overlaypanel:after {
    left: 50%;

    margin-left: 0;

    border-bottom-color: #f5f5f6;
    transform: translateX(-50%);
  }

  .p-overlaypanel-content {
    padding: 0;
  }

  @media(max-width: 1279px) {
    margin-left: 9px;

    &.p-overlaypanel:before {
      left: calc(100% - 24px);
    }


    &.p-overlaypanel:after {
      left: calc(100% - 24px);
    }
  }
}