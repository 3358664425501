/*Copyright Epic Games, Inc. All Rights Reserved.*/

:root {
  --buttonFont: "Gibson";

/*Using colour scheme https://color.adobe.com/TD-Colors---Option-3-color-theme-10394433/*/
  --colour1: #2b3a42;
  --colour2: #3f5765;
  --colour3: #bdd4de;
  --colour4: #efefef;
  --colour5: #ff5035;
  --inputFont: Helvetica;
}

// body{
//     margin: 0px;
//     background-color: black;
// }

#playerUI {
  position: absolute;
  z-index: 10;

  width: 100%;
}

#statsContainer {
  display: none;

  text-align: left;

  background-color: rgba(0,0,0,0.8);
}

#stats {
  padding: 6px;

  font-weight: bold;
  font-size: 14px;
  color: lime;
}

canvas {
  position: absolute;

  image-rendering: crisp-edges;
}

video {
  position: absolute;

  width: 100%;
  height: 100%;
}

#player {
  position: relative;

  width: 100%;
  height: 100%;

  // background-color: #000000;
}

#overlay {
  position: absolute;
  top: 0;
  right: 2%;
  z-index: 100;

  padding: 4px;

  border: 2px solid var(--colour4);
  border-top-width: 0;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-bottomright: 5px;
          border-bottom-right-radius: 5px; /* future proofing */
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomleft: 5px;
          border-bottom-left-radius: 5px; /* future proofing */

  -webkit-user-select: none; /* Safari */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

   -khtml-border-bottom-left-radius: 5px; /* for old Konqueror browsers */
   -khtml-border-bottom-right-radius: 5px; /* for old Konqueror browsers */
   -khtml-user-select: none; /* Konqueror HTML */
  -webkit-touch-callout: none; /* iOS Safari */
}

.overlay {
  font-weight: lighter;
  font-family: var(--buttonFont);
  color: var(--colour4);

  background-color: var(--colour2);
}

#overlayButton:hover {
  cursor: pointer;
}

#overlayButton {
  font-size: 40px;
  text-align: right;
}

#overlaySettings {
  display: none;
  width: 300px;
  padding: 4px;
}

#videoMessageOverlay {
  position: absolute;
  z-index: 20;

  width: 100%;
  margin: auto;

  font-size: 1.8em;
  font-family: var(--inputFont);
  color: var(--colour4);;;
}

#videoPlayOverlay {
  position: absolute;
  z-index: 30;

  width: 100%;
  height: 100%;

  font-size: 1.8em;
  font-family: var(--inputFont);
  color: var(--colour4);

  background-color: rgba(100, 100, 100, 0.7);
}

/* State for element to be clickable */
.clickableState {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

/* State for element to show text, this is for informational use*/
.textDisplayState {
  display: flex;
}

/* State to hide overlay, WebRTC communication is in progress and or is playing */
.hiddenState {
  display: none;
}

#playButton {
  display: inline-block;
  height: auto;
}

img#playButton {
  width: 10%;
  max-width: 241px;
}

#UIInteraction {
  position: fixed;
}

#UIInteractionButtonBoundary {
  padding: 2px;
}

#UIInteractionButton {
  cursor: pointer;
}

#hiddenInput {
  position: absolute;
  left: -10%;   /* Although invisible, push off-screen to prevent user interaction. */

  width: 0;

  opacity: 0;
}

#editTextButton {
  position: absolute;

  width: 40px;
  height: 40px;
}

.settings-text {
  display: inline-block;

  font-weight: normal;
  font-size: 18px;
  vertical-align: middle;
  color: var(--colour4);
}
