.layout {
  display: grid;
  margin: auto;

  @media(min-width: 1280px) {
    max-width: 1100px;
  }

  @media(min-width: 1440px) {
    max-width: 1260px;
  }

  // class will be renamed
  &--threads {
    padding: 0 24px;

    grid-gap: 24px;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(56px, auto) auto auto;

    @media(min-width: 1280px) {
      padding: 0;

      grid-gap: 16px 24px;
      grid-template-columns: minmax(0, 1fr) 338px;
      grid-template-rows: minmax(63px, auto) auto minmax(0, 1fr);
    }
  }

  &--thread-item {
    padding: 0 24px;

    grid-gap: 0;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto auto;

    @media(min-width: 1280px) {
      padding: 0;

      grid-gap: 16px 24px;
      grid-template-columns: minmax(0, 1fr) 338px;
      grid-template-rows: minmax(63px, auto) 64px minmax(0, 1fr);
    }
  }

  &--projects {
    padding: 0 24px;

    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(80px, auto) auto auto;

    @media(min-width: 1280px) {
      padding: 0;

      grid-gap: 9px 0;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: minmax(71px, auto) 64px minmax(0, 1fr);
    }
  }

  &--notifications {
    padding: 0 24px;

    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 80px auto;

    @media(min-width: 1280px) {
      padding: 0;

      grid-gap: 9px 0;
      grid-template-columns: minmax(0, 1fr) 362px;
      grid-template-rows: 71px minmax(0, 1fr);
    }
  }

  &--page {
    padding: 0 24px 40px;

    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 80px auto;

    @media(min-width: 1280px) {
      padding: 0;

      grid-gap: 0;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: 84px minmax(0, 1fr);
    }
  }
}

@media(max-width: 1279px) {
  .layout__heading {
    grid-row: 1 / 2;
  }

  .layout__filter-row {
    grid-row: 2 / 3;
  }

  .layout__sidebar {
    display: none;
  }

  .layout__content {
    grid-row: 3 / 4;
  }
}

@media(max-width: 1279px) {
  .layout__heading {
    position: relative;

    @supports(position: sticky) {
      position: sticky;
      top: 62px;
      z-index: 20;

      background-color: #f6f6f7;
    }
  }

  .layout--threads {
    .layout__sidebar {
      display: block;

      grid-row: 3 / 4;
    }

    .layout__content {
      grid-row: 4 / 5;
    }
  }

  .layout__upper-box {
    height: auto;
  }
}

@media(min-width: 1280px) {
  .layout__heading {
    position: fixed;
    top: 62px;
    right: 0;
    left: 0;
    z-index: 20;

    height: 76px;

    background-color: #f6f6f7;

    .layout__title {
      max-width: 1100px;
      margin: auto;

      @media(min-width: 1440px) {
        max-width: 1260px;
      }
    }
  }

  .layout--threads {
    .layout__heading {
      grid-column: 1 / -1;
      grid-row: 1 / 2;
    }

    .layout__filter-row {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }

    .layout__sidebar {
      // padding-top: 35px;
      grid-column: 2 / -1;
      grid-row: 2 / -1;
    }

    .layout__content {
      grid-column: 1 / 2;
      grid-row: 3 / -1;
    }
  }

  .layout--thread-item {
    .layout__heading {
      grid-column: 1 / -1;
      grid-row: 1 / 2;
    }

    .layout__sidebar {
      grid-column: 2 / -1;
      grid-row: 2 / -1;
    }

    .layout__content {
      grid-column: 1 / 2;
      grid-row: 2 / -1;
    }
  }

  .layout--projects {
    .layout__heading {
      grid-column: 1 / -1;
      grid-row: 1 / 2;
    }

    .layout__filter-row {
      grid-column: 1 / -1;
      grid-row: 2 / 3;
    }

    .layout__content {
      grid-column: 1 / -1;
      grid-row: 3 / -1;
    }
  }

  .layout--page {
    .layout__heading {
      grid-column: 1 / -1;
      grid-row: 1 / 2;
    }

    .layout__box {
      grid-column: 1 / -1;
      grid-row: 2 / -1;
    }
  }

  .layout--notifications {
    .layout__heading {
      grid-column: 1 / -1;
      grid-row: 1 / 2;
    }

    .layout__content {
      grid-column: 1 / 2;
      grid-row: 2 / -1;
    }
  }
}

.layout__box {
  margin-bottom: 20px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}
