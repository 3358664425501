app-video-commenting-tool {
  vg-player *:focus {
    outline: none;
  }
  .video-box {
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 62px;

    video {
      position: relative;

      object-fit: contain;
      object-position: center;
    }
  }
  vg-scrub-bar {
    bottom: 37px;

    height: 25px;

    background: #ffffff;
    border: none;
    border-bottom: 1px solid rgba(224, 224, 224, 0.5);
    opacity: 1;
    .scrubBar {
      height: 6px;

      background: #e0e0e0;

      .time-item {
        position: absolute;
        top: 10px;
        z-index: 100;

        width: 10px;
        height: 10px;
        margin-left: -5px;

        background: #52b683;
        border: 1px solid #52b683;
        border-radius: 50%;
        box-shadow: 0 1px 4px rgba(5, 5, 5, 0.08);

        &.new-comment {
          border-color: #ff7567;
        }
        &.active {
          background-color: #ffffff;
        }
      }

      vg-scrub-bar-current-time {
        z-index: 1;

        height: 6px;
        .background {
          background: #537dea;
        }
      }
      vg-scrub-bar-buffering-time {
        height: 6px;
        .background {
          background: #bdbdbd;
        }
      }
    }
  }

  vg-controls {
    align-items: center;
    height: 37px;
    padding-right: 14px;
    padding-left: 14px;

    background: #ffffff;

    gap: 0 14px;
  }
  // Icons control
  vg-play-pause,
  vg-mute,
  vg-fullscreen {
    width: 20px;
    height: 20px;
  }
  vg-volume {
    height: 20px;
    .volumeBackground {
      background: #e0e0e0;
      .volumeValue {
        background: #537dea;
      }
      .volumeKnob {
        width: 9px;
        height: 9px;
        margin-left: -4.5px;

        background: #52b683;
        border: 1px solid #ffffff;
        border-radius: 50%;
        box-shadow: 0 1px 4px rgba(5, 5, 5, 0.08);
      }
    }
  }
  vg-fullscreen {
    margin-left: auto;
  }
  vg-playback-button,
  vg-time-display {
    color: #818393;
  }
}
[class*=" vg-icon-"],
[class^=vg-icon-] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  line-height: 1;
  font-family: var(--ff);
  text-transform: none;

  pointer-events: all !important;
  &:hover {
    filter: opacity(0.55);
  }
  &::before {
    content: "";

    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-position: center;
  }
}

.vg-icon-pause:before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.667 1.5a2.667 2.667 0 0 1 2.666 2.667v10.666a2.667 2.667 0 1 1-5.333 0V4.167A2.667 2.667 0 0 1 5.667 1.5Zm8.889 0a2.667 2.667 0 0 1 2.666 2.667v10.666a2.667 2.667 0 1 1-5.333 0V4.167A2.667 2.667 0 0 1 14.556 1.5Z' fill='%23818393'/%3E%3C/svg%3E");
}

.vg-icon-play_arrow:before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m16.502 11.238-11.31 6.563C4.232 18.357 3 17.684 3 16.562V3.437C3 2.317 4.23 1.642 5.192 2.2l11.31 6.562a1.425 1.425 0 0 1 0 2.476Z' fill='%23818393'/%3E%3C/svg%3E");
}

// .vg-icon-repeat:before {
//   content: "\e023";
// }

// .vg-icon-replay:before {
//   content: "\e025";
// }

// .vg-icon-skip_next:before {
//   content: "\e027";
// }

// .vg-icon-skip_previous:before {
//   content: "\e028";
// }

// .vg-icon-stop:before {
//   content: "\e02a";
// }


.vg-icon-volume_off:before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.582 5.39 3.366 2.13 2.234 3.278l14.401 14.592 1.131-1.146-1.616-1.637A7.827 7.827 0 0 0 18 10c0-3.317-1.978-6.042-4.8-7.296v1.621C15.11 5.45 16.4 7.606 16.4 10a6.648 6.648 0 0 1-1.337 3.984l-1.029-1.043c.482-.885.767-1.976.767-2.941 0-1.436-.62-3.162-1.6-4.054v6.15l-1.6-1.62V2L6.58 5.39ZM3.6 14.053h2.158L11.6 18v-3.038L2.88 6.127c-.263.134-.486.34-.641.594a1.633 1.633 0 0 0-.24.847v4.864c0 .894.718 1.621 1.6 1.621Z' fill='%23818393'/%3E%3C/svg%3E");
}
.vg-icon-volume_mute:before,
.vg-icon-volume_down:before,
.vg-icon-volume_up:before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.8 10c0 1.436-.62 3.162-1.6 4.053V5.947c.98.892 1.6 2.618 1.6 4.054Zm-9.042 4.053H3.6c-.882 0-1.6-.727-1.6-1.62V7.567c0-.894.718-1.622 1.6-1.622h2.158L11.6 2v16l-5.843-3.947ZM18 9.998c0 3.317-1.978 6.043-4.8 7.297v-1.622c1.91-1.123 3.2-3.28 3.2-5.675 0-2.394-1.29-4.55-3.2-5.675v-1.62C16.023 3.955 18 6.681 18 9.997Z' fill='%23818393'/%3E%3C/svg%3E");
}

.vg-icon-hd:before {
  content: "\e035";
}

// .vg-icon-forward_10:before {
//   content: "\e038";
// }

// .vg-icon-forward_30:before {
//   content: "\e039";
// }

// .vg-icon-replay_10:before {
//   content: "\e03b";
// }

// .vg-icon-replay_30:before {
//   content: "\e03c";
// }

.vg-icon-fullscreen,
.vg-icon-fullscreen_exit {
  cursor: pointer;
}
.vg-icon-fullscreen_exit:before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.073 2.26c.167.167.26.393.26.629V5.11a2.222 2.222 0 0 1-2.222 2.222H2.89a.889.889 0 1 1 0-1.777H5.11a.444.444 0 0 0 .445-.445V2.89a.889.889 0 0 1 1.517-.629Zm0 15.48a.889.889 0 0 0 .26-.629V14.89a2.222 2.222 0 0 0-2.222-2.222H2.89a.889.889 0 1 0 0 1.777H5.11a.445.445 0 0 1 .445.445v2.222a.889.889 0 0 0 1.517.629ZM13.556 2a.889.889 0 0 0-.89.889V5.11a2.222 2.222 0 0 0 2.223 2.222h2.222a.889.889 0 1 0 0-1.777H14.89a.445.445 0 0 1-.445-.445V2.89A.889.889 0 0 0 13.556 2Zm-.629 15.74a.889.889 0 0 1-.26-.629V14.89a2.223 2.223 0 0 1 2.222-2.222h2.222a.889.889 0 1 1 0 1.777H14.89a.445.445 0 0 0-.445.445v2.222a.889.889 0 0 1-1.517.629Z' fill='%23818393'/%3E%3C/svg%3E");
}

.vg-icon-fullscreen:before {
  content: "";

  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.038 4.038a.889.889 0 0 0-.26.629v1.777a.889.889 0 1 1-1.778 0V4.667A2.667 2.667 0 0 1 4.667 2h1.777a.889.889 0 1 1 0 1.778H4.667a.889.889 0 0 0-.629.26Zm0 11.924a.889.889 0 0 1-.26-.629v-1.777a.889.889 0 1 0-1.778 0v1.777A2.667 2.667 0 0 0 4.667 18h1.777a.889.889 0 1 0 0-1.778H4.667a.889.889 0 0 1-.629-.26ZM15.333 3.778a.889.889 0 0 1 .89.889v1.777a.889.889 0 0 0 1.777 0V4.667A2.667 2.667 0 0 0 15.333 2h-1.777a.889.889 0 1 0 0 1.778h1.777Zm.629 12.184a.889.889 0 0 0 .26-.629v-1.777a.889.889 0 0 1 1.778 0v1.777A2.667 2.667 0 0 1 15.333 18h-1.777a.889.889 0 1 1 0-1.778h1.777a.889.889 0 0 0 .629-.26Z' fill='%23818393'/%3E%3C/svg%3E");
}

// Temporary
.time-control-wrap {
  & > span {
    display: none;
  }
}
vg-overlay-play .vg-overlay-play .overlay-play-container.vg-icon-play_arrow {
  height: calc(100% - 63px) !important;
}
vg-overlay-play .vg-overlay-play {
  opacity: 1 !important;
  .vg-icon-play_arrow:before {
    background-size: 40px 40px;
    filter: brightness(1);
  }
}
