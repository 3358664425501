//
// Checkboxes
// --------------------------------------------------

$input-border: #818393 !default;
$input-bg: #ffffff !default;
$input-bg-disabled: #d9dce8 !default;
$input-color: #52b683 !default;
$check-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='12' viewBox='0 0 15 12' fill='none'%3E%3Cpath d='M13.9 1.1C13.8 1 13.7 0.9 13.6 0.9 13.5 0.8 13.4 0.8 13.2 0.8 13.1 0.8 13 0.8 12.8 0.9 12.7 0.9 12.6 1 12.5 1.1L5.1 8.6 1.9 5.4C1.8 5.3 1.7 5.3 1.6 5.2 1.5 5.2 1.3 5.1 1.2 5.1 1.1 5.1 0.9 5.2 0.8 5.2 0.7 5.3 0.6 5.3 0.5 5.4 0.4 5.5 0.3 5.7 0.3 5.8 0.2 5.9 0.2 6 0.2 6.2 0.2 6.3 0.2 6.4 0.3 6.6 0.3 6.7 0.4 6.8 0.5 6.9L4.4 10.7C4.4 10.8 4.6 10.9 4.7 10.9 4.8 11 4.9 11 5.1 11 5.2 11 5.3 11 5.4 10.9 5.6 10.9 5.7 10.8 5.8 10.7L13.9 2.6C14 2.5 14.1 2.4 14.2 2.2 14.2 2.1 14.3 2 14.3 1.8 14.3 1.7 14.2 1.6 14.2 1.4 14.1 1.3 14 1.2 13.9 1.1Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
$check-icon-hover: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='12' viewBox='0 0 15 12' fill='none'%3E%3Cpath d='M13.9 1.1C13.8 1 13.7 0.9 13.6 0.9 13.5 0.8 13.4 0.8 13.2 0.8 13.1 0.8 13 0.8 12.8 0.9 12.7 0.9 12.6 1 12.5 1.1L5.1 8.6 1.9 5.4C1.8 5.3 1.7 5.3 1.6 5.2 1.5 5.2 1.3 5.1 1.2 5.1 1.1 5.1 0.9 5.2 0.8 5.2 0.7 5.3 0.6 5.3 0.5 5.4 0.4 5.5 0.3 5.7 0.3 5.8 0.2 5.9 0.2 6 0.2 6.2 0.2 6.3 0.2 6.4 0.3 6.6 0.3 6.7 0.4 6.8 0.5 6.9L4.4 10.7C4.4 10.8 4.6 10.9 4.7 10.9 4.8 11 4.9 11 5.1 11 5.2 11 5.3 11 5.4 10.9 5.6 10.9 5.7 10.8 5.8 10.7L13.9 2.6C14 2.5 14.1 2.4 14.2 2.2 14.2 2.1 14.3 2 14.3 1.8 14.3 1.7 14.2 1.6 14.2 1.4 14.1 1.3 14 1.2 13.9 1.1Z' fill='%2352B683'/%3E%3C/svg%3E%0A");


.checkbox {
  padding: 7px 0 7px 23px;

  label {
    height: auto;
  }

  .jlabel {
    position: relative;

    display: flex;
    align-items: center;
    min-height: 19px;
    padding-top: 1px;
    padding-left: 9px;

    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: var(--fontColor);
    letter-spacing: 0.005em;

    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      display: inline-block;
      width: 20px;
      height: 20px;
      margin-left: -20px;

      background-color: $input-bg;
      border: 2px solid $input-border;
      border-radius: 2px;

      transition: border 0.15s ease-in-out;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;

      display: inline-block;
      width: 20px;
      height: 20px;
      margin-left: -20px;
      padding-left: 3px;

      font-size: 11px;
      line-height: 1;
      color: $input-color;

      background-color: $input-color;
      background-image: $check-icon;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 14px;
      border-color: $input-color;
      border-radius: 2px;
    }
  }
  @media(pointer: fine) {
    input[type="checkbox"]:hover + .jlabel::after {
      content: "";

      background-color: #ffffff;
      background-image: $check-icon-hover;
      border: 2px solid $input-border;
    }
  }


  &--sm {
    padding: 6px 0 6px 14px;
    .jlabel {
      min-height: 14px;
      padding-top: 0;

      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0;
      &:before {
        width: 13px;
        height: 13px;
        margin-left: -13px;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;

        display: inline-block;
        width: 13px;
        height: 13px;
        margin-left: -13px;
        padding-left: 3px;

        background-size: 9px;
      }
    }
  }
}

.checkbox-inline {
  display: inline-block;
  margin-top: 0;
  padding: 10px 23px;
}

.checkbox[hidden] {
  display: none;
}

.checkbox[disabled],
.checkbox.disabled {
  opacity: 0.38;

  pointer-events: none;
  .jlabel {
    cursor: default;
  }
}


//
// Radios
// --------------------------------------------------


.radio {
  padding: 7px 0 7px 23px;

  label {
    height: auto;
  }

  .jlabel {
    position: relative;

    display: flex;
    align-items: center;
    min-height: 20px;
    padding-top: 0;
    padding-left: 13px;

    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
    color: #415566;
    letter-spacing: 0;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      display: inline-block;
      width: 17px;
      height: 17px;
      margin-left: -17px;

      background-color: $input-bg;
      border: 2px solid $input-border;
      border-radius: 50%;

      transition: border 0.15s ease-in-out;
    }

    &:after {
      position: absolute;
      top: 5px;
      left: 4px;

      display: inline-block;
      width: 7px;
      height: 7px;
      margin-left: -16px;

      background-color: $input-color;
      border-radius: 50%;
    }
  }
}
.radio-inline {
  display: inline-block;
  margin-top: 0;
  margin-right: 30px;
}

.radio[hidden] {
  display: none;
}

.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  position: absolute;
  z-index: 1;

  width: 0;
  height: 0;

  opacity: 0;

  &:hover + .jlabel::before,
  &:active + .jlabel::before,
  &:focus + .jlabel::before {
    cursor: pointer;
  }
  // &:checked + .jlabel::before {
  //   border-color: $input-color;
  // }
  &:checked + .jlabel::after {
    content: "";

    background-color: $input-color;
    background-image: $check-icon;
    border-color: $input-color;
  }
  &:checked:hover + .jlabel::before {
    border-color: #74c49b;
  }
  &:checked:hover + .jlabel::after {
    background-color: #74c49b;
    border-color: #74c49b;
  }
  &:disabled {
    cursor: not-allowed;
  }

  &:disabled + .jlabel {
    opacity: 0.65;

    &:before {
      cursor: not-allowed;
    }
  }
}
