.loading {
  position: relative;

  --animation-duration: 1.5s;

  --line-width: 2px;
  --loader-color-primary: var(--accentPrimary);
  --loader-color-secondary: #e0e0e0;
  --loader-height: 46px;
  --loader-width: 46px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;

    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.54);

    backdrop-filter: blur(4px);
  }

  &--solid::after {
    background-color: rgba(255, 255, 255, 1);

    backdrop-filter: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 23px);
    left: calc(50% - 23px);
    z-index: 9999;

    width: var(--loader-width, 46px);
    height: var(--loader-height, 46px);

    border: var(--line-width, 2px) solid var(--loader-color-secondary, #e0e0e0);
    border-right-color: var(--loader-color-primary, #52b683);
    border-radius: 50%;
    transform-origin: center center;

    animation: circle-loader var(--animation-duration, 1.5s) infinite linear;
  }
}

@keyframes circle-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
 
 }
}
