
// Info:
// - project info
// - file info in commentig tool
// - file info in file viewer

.info {
  width: 100%;
  padding-top: 17px;
  padding-left: 22px;

  @media(min-width: 1280px) {
    padding-top: 50px;
    padding-left: 1px;

    --color: #1e1e1f;
  }
}

.info__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 19px;

  font-size: 14px;
  line-height: 1;
  color: #929292;
  letter-spacing: 0.005em;
  .icon,
  app-icon {
    position: relative;
    top: -1px;

    display: block;
    flex-shrink: 0;
    flex-basis: 32px;
  }

  span {
    flex-shrink: 0;
    width: 79px;
    padding-right: 8px;
  }
  strong,
  a {
    font-weight: normal;
    color: var(--color);
    word-break: break-word;
  }
}
