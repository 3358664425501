p-multiselect {
  width: 100%;

  .p-multiselect:not(.p-disabled).p-focus {
    border-color: #bdbdbd;
    outline: none;
    box-shadow: none;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: none;
    box-shadow: none;
  }

  .p-multiselect {
    position: relative;
    top: 1px;
    min-width: 193px;
    padding: 10px 0 9px 15px;
    font-size: 14px;
    line-height: 20px;
    color: #394452;
    background: #f4f6f9;
    border: none;
    border-radius: 6px;
    -webkit-appearance: none;

    @media(min-width: 1280px) {
      width: 264px;
    }

    .p-multiselect-label {
      display: block;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      font-family: var(--ff);
      color: #818393;
      letter-spacing: 0.005em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .p-multiselect .p-multiselect-label {
    padding: 0;
  }

  &.filter-select {
    .p-multiselect {
      // width: 24px;
      // height: 24px;
      background-color: #ffffff;
      // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none'%3E%3Cpath d='M11 1.2C10.8 1 10.6 0.9 10.3 0.9 10 0.9 9.8 1 9.6 1.2L6 4.7 2.5 1.2C2.3 1 2 0.9 1.8 0.9 1.5 0.9 1.2 1 1 1.2 1 1.3 0.9 1.4 0.8 1.5 0.8 1.6 0.8 1.7 0.8 1.9 0.8 2 0.8 2.1 0.8 2.3 0.9 2.4 1 2.5 1 2.6L5.3 6.8C5.4 6.9 5.5 7 5.6 7 5.7 7.1 5.9 7.1 6 7.1 6.1 7.1 6.3 7.1 6.4 7 6.5 7 6.6 6.9 6.7 6.8L11 2.6C11.1 2.5 11.2 2.4 11.2 2.3 11.3 2.1 11.3 2 11.3 1.9 11.3 1.7 11.3 1.6 11.2 1.5 11.2 1.4 11.1 1.3 11 1.2Z' fill='%23818393'/%3E%3C/svg%3E%0A");
      // background-repeat: no-repeat;
      // background-position: calc(100% - 12px) center;
      // background-size: 12px 8px;
      border: 1px solid #bdbdbd;
      border-radius: 4px;
    }

    .p-multiselect-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc(100% - 32px);
      height: 35px;
      margin-right: 16px;
      margin-left: 16px;
      padding: 0 0;
      background-color: #ffffff;
      border-bottom: 1px solid #e0e0e0;

      .p-multiselect-close {
        display: none;
      }

      .selector__all-option {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #929292;
        text-decoration-line: underline;

        &:not(:first-child)::before {
          content: "|";
          position: relative;
          margin: 0 8px;
          font-size: 14px;
          color: #929292;
        }
      }
    }

    .p-multiselect-panel {
      max-width: 100%;
      margin-top: 8px;

      @media(min-width: 1280px) {
        min-width: 264px;
      }
    }

    .p-multiselect-panel .p-multiselect-items {
      padding: 0;
    }

    .p-multiselect-items-wrapper {
      scrollbar-color: rgba(204, 204, 204, 0.87) transparent;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(204, 204, 204, 0.87);
        outline: none;
      }
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
      min-height: 48px;
      padding-right: 16px;
      padding-left: 17px;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #1e1e1e;
      letter-spacing: 0.005em;
      white-space: normal;

      &:not(.p-highlight):not(.p-disabled):hover {
        background: rgba(82, 182, 131, 0.1);
      }

      &.p-highlight {
        background: transparent;
      }

      .p-checkbox {
        margin-right: 9px;
      }

      & > span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        -webkit-line-clamp: 2;
      }
    }
  }
}