// correct for 25.02.2021

p-editor {
  border-radius: 4px !important;

  .p-editor-container {
    display: flex;
    flex-direction: column;
    .p-editor-toolbar {
      order: 2;
      padding: 10px 8px;

      background-color: #ffffff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .p-editor-content {
      order: 1;

      background: #f6f6f7;
      border-top: 1px solid #dee2e6 !important;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      .ql-editor {
        background: #f6f6f7;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        &:hover {
          box-shadow: 0 0 0 1px #bdbdbd;
        }
        &:focus {
          box-shadow: inset  0 0 0 1px  var(--accentSecondary);
        }
      }
    }
  }
  .ql-container {
    font-size: 16px;
    line-height: 19px;
    color: #bdbdbd;
  }
  .p-editor-toolbar .ql-formats {
    display: inline-flex !important;
    margin-right: 0 !important;

    button {
      color: #bdbdbd;
      svg {
        fill: currentColor;
        stroke: currentColor;
      }

      &.ql-blockquote {
        width: auto;

        color: #bdbdbd;
        &::before {
          content: "Quote";

          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          font-family: var(--ff);
          letter-spacing: 0.005em;
        }
        svg {
          display: none;
        }
        &.ql-active {
          color: var(--accentPrimary);
        }
      }
    }
    &::after {
      content: "";

      width: 1px;
      height: 24px;
      margin-right: 12px;
      margin-left: 12px;

      background-color: #e0e0e0;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
  .ql-editor.ql-blank::before {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
  }
}
.ql-snow .ql-editor blockquote {
  position: relative;

  margin: 0;
  padding-left: 32px;

  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  font-family: "Gibson-Italic";
  color: #1e1e1f;
  letter-spacing: 0.005em;

  border-left: none;

  &::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 8px;

    display: block;
    width: 16px;
    height: 16px;

    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.333 6.667c-.148 0-.29.022-.433.043.046-.155.093-.312.17-.453.075-.206.194-.384.312-.563.099-.194.273-.325.4-.491.135-.162.317-.269.462-.403.142-.14.328-.21.476-.309.155-.088.29-.186.433-.233l.36-.148.316-.131-.324-1.292-.398.096-.459.114c-.18.033-.373.124-.588.208-.212.094-.457.158-.685.31-.23.146-.494.267-.728.462-.226.2-.498.374-.7.63-.22.238-.437.49-.606.774-.195.272-.328.571-.468.866a7.384 7.384 0 0 0-.312.891 8.047 8.047 0 0 0-.256 1.625c-.022.478-.009.876.02 1.164.01.136.028.268.041.36l.017.112.017-.004a3 3 0 1 0 2.933-3.628Zm7.334 0c-.149 0-.292.022-.434.043.046-.155.094-.312.17-.453.076-.206.194-.384.312-.563.1-.194.273-.325.401-.491.134-.162.317-.269.461-.403.142-.14.328-.21.476-.309.155-.088.29-.186.434-.233l.36-.148.315-.131-.323-1.292-.398.096-.46.114c-.18.033-.373.124-.588.208-.21.095-.457.158-.685.311-.23.145-.494.267-.727.461-.226.201-.499.375-.7.63-.22.238-.438.49-.606.774-.196.272-.328.571-.468.866a7.388 7.388 0 0 0-.312.891 8.048 8.048 0 0 0-.256 1.625c-.023.478-.01.876.018 1.164.01.136.03.268.042.36l.017.112.017-.004a3 3 0 1 0 2.934-3.628Z' fill='%23818393'/%3E%3C/svg%3E");
    background-position: center;
    background-size: contain;
  }
}
// reply to message
p-editor .replying {
  .reply {
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: var(--accentSecondary);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-editor-content {
    height: auto !important;

    border: 1px solid var(--accentSecondary) !important;
    border-top: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .ql-editor {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}

// chat in tools
.chat-area {
  .p-editor-toolbar {
    display: none;
  }
  .p-editor-content.ql-snow {
    background-color: transparent;
    border: none !important;
    .ql-editor {
      padding: 0;

      font-size: 14px;
      line-height: 17px;

      background-color: transparent;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

