/*!
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 3/9/21, 2:59 PM
 * Copyright 2021 3D Source
 */

// TODO refactor this fix. Need to check prime-ng native styles
.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.p-inputtext {
  font-family: var(--ff);

  &:focus {
    box-shadow: none !important;
  }
}

// .ng-trigger.p-overlaypanel {
//   z-index: 98 !important;
// }
// Overlay menu from dotted button


.menu-inline-overlay {
  @media(max-width: 1279px) {
    position: static !important;

    width: 100%;

    color: inherit;

    background: transparent;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    border-radius: 0;
    box-shadow: none;

    grid-column: 1 / -1;

    &.p-overlaypanel:after,
    &.p-overlaypanel:before {
      display: none;
    }
  }

  @media(min-width: 1280px) {
    z-index: 1000000;

    min-width: 199px;
    margin-top: 1px !important;
    padding-top: 7px;
    padding-bottom: 7px;

    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgb(185 185 185 / 10%);


    &.p-overlaypanel:before {
      left: 80%;

      border-bottom-color: #e9e9e9;
    }

    &.p-overlaypanel:after {
      left: 80%;

      border-bottom-color: #ffffff;
    }

    .p-overlaypanel-content {
      background-color: transparent;
    }
  }


  .p-overlaypanel-content {
    padding: 0;
  }
}

.p-dialog-mask.p-component-overlay {
  z-index: 1000000 !important;
}