
.p-tooltip {
  max-width: unset !important;

// TODO refactor this !important
  padding: 8px 8px;
}

.p-tooltip.p-tooltip-right,
.p-tooltip.p-tooltip-left {
  padding: 6px 8px;
}

.p-tooltip.p-tooltip-top,
.p-tooltip.p-tooltip-bottom {
  padding: 6px 8px;
}

.p-tooltip .p-tooltip-text {
  padding: 6px 8px 4px !important;

  font-size: 10px;
  line-height: 130%;
  font-family: var(--ff);
  color: #1e1e1f;
  letter-spacing: 0.005em;

  background-color: #ffffff !important;
  border: 1px solid #bdbdbd !important;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(185, 185, 185, 0.1) !important;
}

.p-tooltip .p-tooltip-arrow {
  position: absolute;
  z-index: 2;

  width: 0;
  height: 0;

  border-style: solid;
  border-color: transparent;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;

    width: 0;
    height: 0;

    border-style: solid;
    border-color: transparent;
  }
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  top: 50%;
  left: 2px;

  margin-top: -6px;

  border-width: 6px 6px 6px 0;
  border-right-color: #bdbdbd !important;

  &::before {
    top: 50%;
    left: 1px;

    margin-top: -6px;

    border-width: 6px 6px 6px 0;
    border-right-color: #ffffff;
  }
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  top: 50%;
  right: 2px;

  margin-top: -6px;

  border-width: 6px 0 6px 6px;
  border-left-color: #bdbdbd !important;

  &::before {
    top: 50%;
    right: 1px;

    margin-top: -6px;

    border-width: 6px 0 6px 6px;
    border-left-color: #ffffff;
  }
}

.p-tooltip.p-tooltip-top {
  padding: 6px 0;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  bottom: 0;
  left: 50%;

  margin-left: -6px;

  border-width: 6px 6px 0;
  border-top-color: #bdbdbd !important;

  &::before {
    bottom: 1px;
    left: 50%;

    margin-left: -6px;

    border-width: 6px 6px 0;
    border-top-color: #ffffff;
  }
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  top: 0;
  left: 50%;

  margin-left: -6px;

  border-width: 0 6px 6px;
  border-bottom-color: #bdbdbd !important;

  &::before {
    top: 1px;
    left: 50%;

    margin-left: -6px;

    border-width: 0 6px 6px;
    border-bottom-color: #ffffff ;
  }
}

// grey tooltips\
.settings-tooltip.p-tooltip .p-tooltip-text {
  max-width: 160px;
  padding: 6px 8px 8px !important;

  background: #f5f5f6 !important;
  border-color: #e9e9e9 !important;
  box-shadow: 0 2px 4px rgba(185, 185, 185, 0.24) !important;
}

