// UI fonts
// -- Avenir Next

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Avenir Next";

  font-display: swap;
  src: url("^assets/fonts/AvenirNextCyr-Demi.woff2") format("woff2");
}
@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Avenir Next";

  font-display: swap;
  src: url("^assets/fonts/AvenirNextCyr-Medium.woff2") format("woff2");
}

// -- Gibson

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Gibson";

  font-display: swap;
  src: url("^assets/fonts/GibsonRegular.woff2") format("woff2");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Gibson";

  font-display: swap;
  src: url("^assets/fonts/Gibson-SemiBold.woff2") format("woff2");
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: "Gibson";

  font-display: swap;
  src: url("^assets/fonts/Gibson-LightItalic.woff2") format("woff2");
}
