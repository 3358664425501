.form {
  position: relative;
}

.form__elem {
  width: 100%;
  margin-bottom: 32px;

  @media(min-width: 1280px) {
    margin-bottom: 24px;
  }
  @media(min-width: 1400px) {
    margin-bottom: 32px;
  }
}

.form__label {
  display: block;
  align-items: center;
  margin-bottom: 2px;

  font-size: 16px;
  line-height: 19px;
  color: var(--fontColor);
  letter-spacing: 0.005em;

  cursor: text;

  &--hinted {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media(min-width: 1280px) {
      flex-direction: row;
      align-items: center;
    }
  }
}

.form__label-hint {
  margin-top: 3px;
  margin-bottom: 3px;

  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #929292;
  letter-spacing: 0.005em;

  @media(min-width: 1280px) {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    padding-left: 10px;
  }
}

.form__input {
  position: relative;

  width: 100%;

  input {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 13px 22px 12px;

    font-size: 16px;
    line-height: 19px;
    color: var(--fontColor);
    letter-spacing: 0.005em;

    background: #f6f6f7;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    &::placeholder {
      color: #bdbdbd;
    }

    &:hover {
      color: var(--fontColor);

      border-color: #bdbdbd;
    }

    &:focus {
      color: var(--fontColor);

      border-color: var(--accentSecondary);
    }

    &.invalid {
      border-color: #eb5757;
    }
  }

  textarea {
    width: 100%;
    height: 100px;
    padding: 14px 24px;

    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: var(--fontColor);
    letter-spacing: 0.005em;

    background: #f6f6f7;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    resize: vertical;

    &::placeholder {
      color: #bdbdbd;
    }

    &:hover {
      color: var(--fontColor);

      border-color: #bdbdbd;
    }

    &:focus {
      color: var(--fontColor);

      border-color: var(--accentSecondary);
    }

    &.invalid {
      border: 1px solid #eb5757;
    }
  }

  select {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 13px 22px 12px;

    font-size: 16px;
    line-height: 19px;
    color: var(--fontColor);
    letter-spacing: 0.005em;

    background-color: #f6f6f7;
    background-image: url("^assets/images/select-arrow.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 11px;
    background-size: 24px 24px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    appearance: none;
    &::after {
      content: "";

      width: 0.8em;
      height: 0.5em;

      background-color: var(--select-arrow);

      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    }
    &::placeholder {
      color: #bdbdbd;
    }

    &:hover {
      color: var(--fontColor);

      border-color: #bdbdbd;
    }

    &:focus {
      color: var(--fontColor);

      border-color: var(--accentSecondary);
      outline: none;
    }

    &.invalid {
      border-color: #eb5757;
    }
  }

  .postfix {
    position: absolute;
    top: 0;
    right: 0;

    width: var(--postfixW);
    height: calc(100% - 1px);
    padding-top: 6px;
    padding-bottom: 6px;

    background-color: #ffffff;

    & + input {
      padding-right: var(--postfixW);
    }
  }
}

.form__show-password-btn {
  position: absolute;
  top: 50%;
  right: 0;

  margin-right: 5px;

  color: #818393;

  transform: translateY(-50%);
}

.form__select {
  position: relative;

  display: block;
  min-width: 200px;
  padding: 8px 30px 8px 10px;

  font-size: 14px;
  line-height: 20px;
  color: #394452;

  background: #f4f6f9;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16' fill='none'%3E%3Cpath d='M11.4 7.2L7.7 10.9 4 7.2 4.7 6.5 7.7 9.5 10.7 6.5 11.4 7.2Z' fill='%232C3A4B'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 177px 9px;
  background-size: 15px 16px;
  border: none;
  border-radius: 6px;

  -webkit-appearance: none;
}

.form__hint {
  margin-top: 4px;

  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #929292;
  letter-spacing: 0.005em;

  &.invalid {
    color: #eb5757;
  }
}
.form__buttons {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 38px;

  .button {
    width: 100%;

    @media(min-width: 1280px) {
      width: auto;
      min-width: 123px;
    }
  }

  .button + .button {
    margin-left: 24px;
  }
}

// Toggle
.toggle {
  display: flex;
  align-items: center;
}

.toggle {
  .toggle__button {
    position: relative;

    width: 40px;
    height: 20px;
    margin: 0;
    padding: 0;

    vertical-align: top;

    background: #e0e0e0;
    border: none;
    border-radius: 10px;
    outline: none;
    cursor: pointer;

    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;

    &:hover {
      background: #e8e8e8;
      //background: #74c49b;
    }

    &:active {
      background: #bbbbbb;
    }

    &:disabled,
    &[disabled] {
      background-color: #efefef;
      cursor: default;
      opacity: 1;

      pointer-events: none;
    }

    &::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 3px;

      width: 16px;
      height: 16px;

      background-color: #ffffff;
      border-radius: 50%;
      box-shadow: -1px 1px 4px rgba(143, 143, 143, 0.12);
      transform: translateX(0);

      transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }

    &:checked::after {
      background-color: #ffffff;
      transform: translateX(calc(100% + 3px));
    }

    &:checked {
      background-color: var(--accentPrimary);
      &:hover {
        background: #74c49b;
      }

      &:active {
        background: #388a60;
      }
      &:disabled,
      &[disabled] {
        background-color: #bde3d0;
        cursor: default;
        opacity: 1;

        pointer-events: none;
      }
    }
  }
  .toggle__label {
    display: flex;
    align-items: center;
    margin-left: 16px;

    font-size: 16px;
    line-height: 19px;
    color: #929292;
    letter-spacing: 0.005em;

    cursor: pointer;
  }
  .toggle__button:checked + .toggle__label {
    color: var(--fontColor);
  }
}
input[type="password"]::-ms-reveal {
  display: none;
}
