.service-modal {
  max-width: 459px;

  @media(max-width: 1279px) {
    max-width: 459px;
    height: auto !important;
    max-height: 90%;
  }


  &.p-dialog .p-dialog-header {
    align-items: flex-start;
    padding-bottom: 16px;
  }

  &--warning {
    .p-dialog-header .p-dialog-title::before {
      content: "";

      display: inline-block;
      width: 24px;
      height: 24px;
      margin-top: -1px;
      margin-right: 11px;
      margin-left: -1px;

      vertical-align: top;

      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 11C11.7 11 11.5 11.1 11.3 11.3 11.1 11.5 11 11.7 11 12V16C11 16.3 11.1 16.5 11.3 16.7 11.5 16.9 11.7 17 12 17 12.3 17 12.5 16.9 12.7 16.7 12.9 16.5 13 16.3 13 16V12C13 11.7 12.9 11.5 12.7 11.3 12.5 11.1 12.3 11 12 11ZM12.4 7.1C12.1 7 11.9 7 11.6 7.1 11.5 7.1 11.4 7.2 11.3 7.3 11.2 7.4 11.1 7.5 11.1 7.6 11 7.7 11 7.9 11 8 11 8.1 11 8.3 11.1 8.4 11.1 8.5 11.2 8.6 11.3 8.7 11.4 8.8 11.5 8.9 11.6 8.9 11.8 9 11.9 9 12.1 9 12.3 9 12.4 8.9 12.6 8.8 12.7 8.7 12.8 8.6 12.9 8.5 13 8.3 13 8.2 13 8 13 7.7 12.9 7.5 12.7 7.3 12.6 7.2 12.5 7.1 12.4 7.1ZM12 2C10 2 8.1 2.6 6.4 3.7 4.8 4.8 3.5 6.3 2.8 8.2 2 10 1.8 12 2.2 14 2.6 15.9 3.5 17.7 4.9 19.1 6.3 20.5 8.1 21.4 10 21.8 12 22.2 14 22 15.8 21.2 17.7 20.5 19.2 19.2 20.3 17.6 21.4 15.9 22 14 22 12 22 10.7 21.7 9.4 21.2 8.2 20.7 7 20 5.9 19.1 4.9 18.1 4 17 3.3 15.8 2.8 14.6 2.3 13.3 2 12 2ZM12 20C10.4 20 8.9 19.5 7.6 18.7 6.2 17.8 5.2 16.5 4.6 15.1 4 13.6 3.8 12 4.2 10.4 4.5 8.9 5.2 7.5 6.3 6.3 7.5 5.2 8.9 4.5 10.4 4.2 12 3.8 13.6 4 15.1 4.6 16.5 5.2 17.8 6.2 18.7 7.6 19.5 8.9 20 10.4 20 12 20 14.1 19.2 16.2 17.7 17.7 16.2 19.2 14.1 20 12 20Z' fill='%23F2994A'/%3E%3C/svg%3E%0A");
    }
  }
  .service-modal__content {
    min-height: 103px;
    padding-right: 42px;
    padding-left: 42px;

    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #929292;

    @media(max-width: 1279px) {
      padding: 20px 24px;
    }
  }
}


.service-modal__footer {
  display: flex;
  align-items: center;
  padding-right: 42px;
  padding-bottom: 39px;
  padding-left: 42px;

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }
  .button {
    min-width: 123px;
    margin: 0;

    &.delete {
      @extend .button--red;
    }
  }
  .button + .button {
    margin-left: 23px;
  }
}

.sharing-modal {
  max-width: 634px;
  @media(max-width: 1279px) {
    max-width: 98%;
    height: auto !important;
    max-height: 90%;
  }


  &.p-dialog .p-dialog-header {
    align-items: flex-start;
    padding-bottom: 16px;
  }
  .sharing-modal__content {
    min-height: 103px;
    padding-right: 42px;
    padding-bottom: 36px;
    padding-left: 42px;

    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #929292;

    @media(max-width: 1279px) {
      padding: 20px 24px;
    }
  }
}

.p-dialog-mask {
  background-color: rgba(97, 97, 97, 0.55) !important;
}
.p-dialog {
  box-sizing: border-box;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-shadow: none;


  @media(max-width: 1279px) {
    width: 100%;
    height: 100%;
    max-height: 100% !important;
  }

  @media(min-width: 1280px) {
    width: 910px;
    max-height: 92.7%;
  }
  &.gallery {
    max-height: 100%;

    background-color: transparent;
    border: none;
    .p-dialog-header {
      display: none !important;
    }
  }
  &.files {
    @media(min-width: 1280px) {
      width: 784px;
    }
  }
  .p-dialog-header {
    padding: 20px 24px;

    color: var(--fontColor);
    @media(min-width: 1280px) {
      padding: 36px 42px;
    }
    @media(min-width: 1440px) {
      padding: 36px 41px 30px 42px;
    }
    .p-dialog-header-close-icon {
      display: block;
      width: 24px;
      height: 24px;

      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M13.4 12L17.7 7.7C17.9 7.5 18 7.3 18 7 18 6.7 17.9 6.5 17.7 6.3 17.5 6.1 17.3 6 17 6 16.7 6 16.5 6.1 16.3 6.3L12 10.6 7.7 6.3C7.5 6.1 7.3 6 7 6 6.7 6 6.5 6.1 6.3 6.3 6.1 6.5 6 6.7 6 7 6 7.3 6.1 7.5 6.3 7.7L10.6 12 6.3 16.3C6.2 16.4 6.1 16.5 6.1 16.6 6 16.7 6 16.9 6 17 6 17.1 6 17.3 6.1 17.4 6.1 17.5 6.2 17.6 6.3 17.7 6.4 17.8 6.5 17.9 6.6 17.9 6.7 18 6.9 18 7 18 7.1 18 7.3 18 7.4 17.9 7.5 17.9 7.6 17.8 7.7 17.7L12 13.4 16.3 17.7C16.4 17.8 16.5 17.9 16.6 17.9 16.7 18 16.9 18 17 18 17.1 18 17.3 18 17.4 17.9 17.5 17.9 17.6 17.8 17.7 17.7 17.8 17.6 17.9 17.5 17.9 17.4 18 17.3 18 17.1 18 17 18 16.9 18 16.7 17.9 16.6 17.9 16.5 17.8 16.4 17.7 16.3L13.4 12Z' fill='%23818393'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .p-dialog-header-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    }
    .p-dialog-header-icon:hover {
      .p-dialog-header-close-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M13.4 12L17.7 7.7C17.9 7.5 18 7.3 18 7 18 6.7 17.9 6.5 17.7 6.3 17.5 6.1 17.3 6 17 6 16.7 6 16.5 6.1 16.3 6.3L12 10.6 7.7 6.3C7.5 6.1 7.3 6 7 6 6.7 6 6.5 6.1 6.3 6.3 6.1 6.5 6 6.7 6 7 6 7.3 6.1 7.5 6.3 7.7L10.6 12 6.3 16.3C6.2 16.4 6.1 16.5 6.1 16.6 6 16.7 6 16.9 6 17 6 17.1 6 17.3 6.1 17.4 6.1 17.5 6.2 17.6 6.3 17.7 6.4 17.8 6.5 17.9 6.6 17.9 6.7 18 6.9 18 7 18 7.1 18 7.3 18 7.4 17.9 7.5 17.9 7.6 17.8 7.7 17.7L12 13.4 16.3 17.7C16.4 17.8 16.5 17.9 16.6 17.9 16.7 18 16.9 18 17 18 17.1 18 17.3 18 17.4 17.9 17.5 17.9 17.6 17.8 17.7 17.7 17.8 17.6 17.9 17.5 17.9 17.4 18 17.3 18 17.1 18 17 18 16.9 18 16.7 17.9 16.6 17.9 16.5 17.8 16.4 17.7 16.3L13.4 12Z' fill='%23ABADBA'/%3E%3C/svg%3E%0A");
      }
    }
    .p-dialog-header-icon:active {
      .p-dialog-header-close-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M13.4 12L17.7 7.7C17.9 7.5 18 7.3 18 7 18 6.7 17.9 6.5 17.7 6.3 17.5 6.1 17.3 6 17 6 16.7 6 16.5 6.1 16.3 6.3L12 10.6 7.7 6.3C7.5 6.1 7.3 6 7 6 6.7 6 6.5 6.1 6.3 6.3 6.1 6.5 6 6.7 6 7 6 7.3 6.1 7.5 6.3 7.7L10.6 12 6.3 16.3C6.2 16.4 6.1 16.5 6.1 16.6 6 16.7 6 16.9 6 17 6 17.1 6 17.3 6.1 17.4 6.1 17.5 6.2 17.6 6.3 17.7 6.4 17.8 6.5 17.9 6.6 17.9 6.7 18 6.9 18 7 18 7.1 18 7.3 18 7.4 17.9 7.5 17.9 7.6 17.8 7.7 17.7L12 13.4 16.3 17.7C16.4 17.8 16.5 17.9 16.6 17.9 16.7 18 16.9 18 17 18 17.1 18 17.3 18 17.4 17.9 17.5 17.9 17.6 17.8 17.7 17.7 17.8 17.6 17.9 17.5 17.9 17.4 18 17.3 18 17.1 18 17 18 16.9 18 16.7 17.9 16.6 17.9 16.5 17.8 16.4 17.7 16.3L13.4 12Z' fill='%235D5F6F'/%3E%3C/svg%3E%0A");
      }
    }
    .p-dialog-header-icon:focus {
      box-shadow: none;
    }
    .p-dialog-header-icon:enabled:hover {
      background-color: transparent;
    }
    .p-dialog-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 136%;
      font-family: "Avenir Next", sans-serif;
      color: var(--fontColor);
      text-transform: uppercase;
      word-break: break-word;
    }
  }
}


.p-dialog .p-dialog-content {
  padding: 0;

  background: transparent;
}

// Modal with header and footer
.p-dialog.form-modal {
  display: grid;

  grid-template-rows: auto minmax(0, 1fr);
  @media(min-width: 1280px) {
    max-height: 93%;
  }
  @media(min-width: 1440px) {
    min-height: 85%;
  }
  .p-dialog-header {
    @media (min-width: 1280px) {
      padding: 23px 42px;
    }
    @media (min-width: 1440px) {
      padding: 36px 42px;
    }
  }
  .p-dialog-content {
    position: relative;

    max-height: 100%;
    overflow: hidden;
  }
  .form-modal__body {
    padding: 0 24px;
    overflow: auto;
    @media(min-width: 1280px) {
      padding: 0 57px;
    }
    @media(min-width: 1440px) {
      padding: 0 58px;
    }
  }
  .form-modal__footer {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-height: 64px;
    padding-right: 24px;
    padding-left: 24px;

    border-top: 1px solid #e0e0e0;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 -4px 12px rgba(219, 219, 219, 0.32);

    gap: 0 24px;

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }
    @media(min-width: 1280px) {
      padding: 0 42px;
    }

    @media(min-width: 1440px) {
      padding: 0 58px;
    }

    .button {
      min-width: 124px;
      @media(max-width: 700px) {
        flex-grow: 1;
      }
    }
  }
}
// Modal with header and footer
.p-dialog.roles-modal {
  display: grid;

  grid-template-rows: auto minmax(0, 1fr);

  @media(min-width: 1280px) {
    width: 631px;
    max-height: 93%;
  }

  @media(min-width: 1440px) {
    min-height: 85%;
  }

  .p-dialog-header {
    @media (min-width: 1280px) {
      padding: 35px 39px 18px;
    }
  }

  .p-dialog-content {
    position: relative;

    display: grid;
    max-height: 100%;
    overflow: hidden;

    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
  }

  .roles-modal__body {
    width: 100%;
    height: calc(100% - 64px);
    padding: 0 24px;

    @media(min-width: 1280px) {
      height: 100%;
      padding: 0 40px;
    }

    @media(min-width: 1440px) {
      padding: 0 40px;
    }
  }

  .roles-modal__footer {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-height: 64px;
    padding-right: 42px;
    padding-left: 42px;

    border-top: 1px solid #e0e0e0;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 -4px 12px rgba(219, 219, 219, 0.32);

    gap: 0 24px;

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }

    @media(min-width: 1280px) {
      padding: 0 42px;
    }

    @media(min-width: 1440px) {
      padding: 0 58px;
    }

    .button {
      min-width: 124px;
    }
  }
}
